<template>
    <SModal>
        <form class="col " @submit.prevent="post">
            <div class="d-flex space-between align-center">
                <div class="font-18">{{ $t("modal.updateProfile.title") }}</div>
                <s-icon color="grey" @click="closeModal"> close </s-icon>
            </div>
            <div class="col mt-20 gap-10">
                <span>{{ $t("modal.updateProfile.subtitle") }} </span>
            </div>
            <div class="center mt-40 missingText red--text font-14">
                {{ message }}
            </div>

            <div class="row align-center gap-20">
                <div class="font-14 opacity-60">
                    {{ $t("modal.account.email") }}
                </div>
                <s-text-field v-model="email" block height="30" />
            </div>

            <div class="d-flex justify-end mt-40">
                <s-btn type="button" height="36" class="mr-10" @click="closeModal">
                    {{ $t("modal.close") }}
                </s-btn>
                <s-btn type="submit" height="36" class="mr-10 green">
                    {{ $t("modal.save") }}
                </s-btn>
            </div>
        </form>
    </SModal>
</template>
  
<script setup>
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useStore } from "vuex";
import { validateEmail } from "@/assets/functions.js"
const store = useStore();
const { t } = useI18n();
const message = ref("");
const email = ref(null);

const post = async () => {
    message.value = ""
    if (!validateEmail(email.value)) message.value = t("server.format_email")
    let response = await store.dispatch("user/setProfile", { email: email.value });
    if (response.status == "ok") return closeModal();
    if (response.status == "error") message.value = t(`server.${response.errorCode}`);
};

const closeModal = () => {
    store.commit("modals/updateProfile", false);
};

</script>