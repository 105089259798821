<template>
  <FocusLoop>
    <div ref="modal_page" class="modal_page center">
      <form ref="modal_window" class="modal_window d-flex center d-col space-between" @submit.prevent="post">
        <div class="d-flex space-between align-center w100">
          <div class="font-18 w100 text-center">
            {{ t("modal.firstTime.title") }}
          </div>
          <s-icon color="grey" @click="closeModal">
            close
          </s-icon>
        </div>
        <hr class="my-20 w100">
        <div class="center d-col w100">
          <div class="mb-20">
            {{ t("modal.firstTime.one") }}
          </div>
          <s-timezone v-model="timezone" :prefill="timezone" height="40" class="mb-10" />
          <div class="grey--text font-12 italic">
            {{ t("modal.firstTime.example") }}
          </div>
          <div class="mt-40 mb-20">
            {{ t("modal.firstTime.two") }}
          </div>
          <div class="d-flex w100 relative">
            <div class="color_box" :style="{ backgroundColor: 'var(--sm-color-' + colorID + ')' }" @click="togglePicker">
              <s-color-picker v-if="showPicker" v-model="colorID" />
            </div>
            <div class="box center mr-10" @click="togglePicker">
              <s-icon class="pa-5" height="16" color="grey">
                chevron-down
              </s-icon>
            </div>
            <s-text-field v-model="roomName" block :error="error" placeholder="Meeting Room Name…" />
          </div>
        </div>
        <div class="mt-40 button_wrapper">
          <s-btn block type="submit" height="36" class="green">
            {{ t("modal.firstTime.save") }}
          </s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { gsap } from "gsap";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import { useMq } from "vue3-mq";
import { useRouter } from 'vue-router';

const store = useStore();
const { t } = useI18n();
const mq = useMq();
const router = useRouter();

const showPicker = ref(false);
const roomName = ref("");
const colorID = ref(1);
const timezone = ref("");
const error = ref('');
const modal_page = ref(null);
const modal_window = ref(null);

const location = computed(() => store.state.user.location);
const timezones = computed(() => store.state.user.timezones);
const rooms = computed(() => store.state.rooms.rooms);

const post = async () => {
  await store.dispatch("user/setProfile", { timezone: timezone.value });
  let roomsInfo = await store.dispatch("rooms/getRoomsInformation");
  store.commit("rooms/editRoom", roomsInfo[0]);

  if (roomName.value) {
    store.commit("rooms/setEditRoomName", roomName.value);
  }

  store.commit("rooms/editColor", colorID.value);
  let res = await store.dispatch("rooms/updateRoom");

  error.value = t('server.' + res.errorCode);
  if (res.status === "ok") closeModal();
}

const togglePicker = () => {
  showPicker.value = !showPicker.value;
}

const closeModal = () => {
  gsap.to(modal_page.value, {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out"
  });

  gsap.to(modal_window.value, {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
    onComplete: () => store.commit("modals/firstTimeWizardModal", false)
  });
}

onMounted(() => {
  if (location.value && (location.value.timezone === 'UTC' || location.value.timezone === null)) {
    timezone.value = 'Europe/London';
  } else if (location.value) {
    timezone.value = location.value.timezone;
  }

  if (rooms.value && rooms.value.length > 0) {
    colorID.value = rooms.value[0].color;
  }

  gsap.from(modal_page.value, {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out"
  });

  gsap.from(modal_window.value, {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out"
  });
})
</script>


<style scoped>
.box {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #151b23;
  margin-left: 2px;
}

.color_box {
  min-width: 40px;
  height: 40px;
  border: 8px solid #17232e;
  box-sizing: border-box;
}

.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

.button_wrapper {
  width: 258px;
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>