<template>
  <div ref="timePicker" class="relative">
    <button type="button" class="d-flex w100" @click="showPicker">
      <div class="w100">
        <input ref="button" v-model="selected" type="text" data-lpignore="true" :style="inputStyling" :disabled="!noedit" @keydown.enter="showPicker" @keydown.prevent.space="showPicker('space')" @keydown.esc="onBlur">
        <div ref="line" class="line" />
      </div>
      <button v-if="noedit" tabindex="-1" type="button" class="box center" :style="boxStyling">
        <s-icon height="16" color="grey">
          clock-outline
        </s-icon>
      </button>
    </button>

    <transition name="dropdown">
      <div v-show="show" v-hotkey="{ esc: onBlur }" class="clockContainer">
        <FocusLoop>
          <div class="time">
            <div class="d-flex pl-40">
              <button class="opacity-40 pointer" type="button" :class="{ selected: hours }" @click="hours = true">
                {{ time.format("hh") }}
              </button>
              <div>:</div>
              <button class="opacity-40 pointer" type="button" :class="{ selected: !hours }" @click="hours = false">
                {{ time.format("mm") }}
              </button>
            </div>
            <div class="center d-col ml-10">
              <button class="font-18 opacity-40 pointer" type="button" :class="{ selected: am }" @click="switchToAm()">
                AM
              </button>
              <button class="font-18 opacity-40 pointer" type="button" :class="{ selected: !am }" @click="switchToPm()">
                PM
              </button>
            </div>
          </div>
          <div v-if="hours" class="center w100 h100">
            <div class="clock center noSelect">
              <div class="hand" />
              <div class="dot" />
              <button v-for="index in 12" :key="index" type="button" :class="'time-' + index" @click="select(index * 30, $event.target)">
                {{ index }}
              </button>
            </div>
          </div>
          <div v-if="!hours" class="center w100 h100">
            <div class="clock center noSelect">
              <div class="hand" />
              <div class="dot" />
              <button v-for="index in 12" :key="index" type="button" :class="'time-' + index" @click="select(index * 30, $event.target)">
                {{ (index * 5) % 60 }}
              </button>
            </div>
          </div>
        </FocusLoop>
      </div>
    </transition>
  </div>
</template>

<script setup>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

const emit = defineEmits();
const { height, prefill, noedit } = defineProps(["height", "prefill", "noedit"]);

const lang = computed(() => store.state.user.lang);


let time = ref(dayjs(prefill, "hh:mm A"));
let show = ref(false);
let hours = ref(true);
let am = ref(time.value.format("A") == "AM");
let selected = ref(dayjs().locale(lang.value).format("hh:mm A"));
const line = ref(null);
const button = ref(null);
const timePicker = ref(null);



const inputStyling = computed(() => {
  let ob = {};
  if (height) ob.height = height + "px";
  if (!noedit) ob.backgroundColor = "#00000000";
  if (show.value) ob.backgroundColor = "rgba(0, 0, 0, 0.45)";
  return ob;
});

const boxStyling = computed(() => {
  let ob = {};
  if (height) ob.height = height + "px";
  if (height) ob.minWidth = height + "px";
  if (show.value) ob.backgroundColor = "#000000";
  return ob;
});

const switchToAm = () => {
  let tempTime = time.value.subtract(12, "hour");
  if (!am.value) time.value = tempTime;
  am.value = true;
};

const switchToPm = () => {
  if (am.value) time.value = time.value.add(12, "hour");
  am.value = false;
};

const onFocus = () => {
  line.value.style.width = "100%";
  button.value.focus();
};

const onBlur = () => {
  show.value = false;
  line.value.style.width = "0";
  checkInputTime();
};

const checkInputTime = () => {
  let normalizedTime = selected.value.trim().toUpperCase().split(" ").join("");
  if (normalizedTime.split(":")[0].length == 1) normalizedTime = `0${normalizedTime}`;
  let valid = dayjs(normalizedTime, "hh:mmA", true).isValid();
  selected.value = dayjs(normalizedTime, "hh:mmA").format("hh:mm A");
  if (!valid) selected.value = dayjs().locale(lang.value).format("hh:mm A");
  button.value.blur();
  let mod = modFive(selected.value);
  if (mod.charAt(0) == "0") return preSelectAtime(mod.substring(1));
  preSelectAtime(mod);
};

const modFive = (val) => {
  let tempTime = dayjs(val, "hh:mm A");
  let mins = tempTime.format("mm");
  let ceil = Math.ceil(mins / 5) * 5;
  tempTime = tempTime.minute(ceil);
  return tempTime.format("hh:mm A");
};

const listen = (event) => {
  if (!event) return;
  if (!timePicker.value.contains(event.target)) {
    show.value = false;
    onBlur();
  }
};

const showPicker = (key) => {
  let index = button.value.selectionStart;
  if (key == "space") {
    const pair = Array.from(selected.value);
    pair.splice(index, 0, " ");
    selected.value = pair.join("");
    setTimeout(() => {
      setCaretPosition(index + 1);
    }, 0);
    return;
  }
  if (!noedit) return;
  show.value = !show.value;
  if (show.value) onFocus();
  if (!show.value) onBlur();
};

const select = (val, el) => {
  if (!el) return;
  let tempTime = hours.value ? time.value.hour(el.innerHTML) : time.value.minute(el.innerHTML);
  tempTime = dayjs(tempTime.format("hh:mm").concat(am.value ? " AM" : " PM"), "hh:mm A");
  updateUI(val, el);
  time.value = tempTime;
  emit("changeTime", tempTime);
};

const setCaretPosition = (position) => {
  let element = button.value;
  element.focus();
  if (element.setSelectionRange) {
    element.setSelectionRange(position, position);
  } else if (element.createTextRange) {
    const range = element.createTextRange();
    range.collapse(true);
    range.moveEnd("character", position);
    range.moveStart("character", position);
    range.select();
  }
};

const updateUI = (val, el) => {
  let nodes = document.getElementsByClassName("clock")[0]?.childNodes;
  if (!nodes) return;
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i] instanceof Element) {
      nodes[i].classList.remove("active");
    }

  }
  el.classList.add("active");
  let hand = document.getElementsByClassName("hand")[0];
  hand.style.transform = `rotate(${val}deg) scaleY(1)`;
};

const preSelectAtime = async (timeStr) => {
  let hour = timeStr.split(" ")[0].split(":")[0];
  let mins = timeStr.split(" ")[0].split(":")[1];
  let el_hours = document.getElementsByClassName(`time-${hour}`)[0];
  let el_mins = document.getElementsByClassName(`time-${mins / 5}`)[0];
  if (mins == "00") mins = 60;
  if (hours.value) select(hour * 30, el_hours);
  else select((mins / 5) * 30, el_mins);
};

function wait(ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

watch(am, (val) => {
  if (noedit) emit("changeTime", time.value);
});

watch(time, (val) => {
  selected.value = dayjs(val).locale(lang.value).format("hh:mm A");
});

watch(hours, async (val) => {
  await wait();
  preSelectAtime(prefill);
});

watch(lang, async () => {
  selected.value = dayjs().locale(lang.value).format("hh:mm A");
});

time.value = dayjs(prefill, "hh:mm A");
if (time.value.format("A") == "AM") am.value = true;

onMounted(() => {
  selected.value = dayjs().locale(lang.value).format("hh:mm A");
  preSelectAtime(prefill);
  window.addEventListener("click", listen);
});

onUnmounted(() => {
  window.removeEventListener("click", listen);
});

</script>


<style scoped>
.clock>button {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.active {
  background-color: #409875;
}

.selected {
  opacity: 1;
}

.hand {
  height: calc(50% - 15px);
  width: 2px;
  bottom: 50%;
  left: calc(50% - 1px);
  transform-origin: center bottom;
  position: absolute;
  will-change: transform;
  z-index: 1;
  background-color: #409875;
}

.dot {
  position: absolute;
  bottom: 50%;
  transform: translate(0%, 50%);
  height: 5px;
  width: 5px;
  background-color: #409875;
  border-radius: 50%;
}

.time-12 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 0%;
}

.time-1 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 75%;
  top: 6.69873%;
  transform: translate(-50%, -50%);
}

.time-2 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 93.3013%;
  top: 25%;
}

.time-3 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 100%;
  top: 50%;
}

.time-4 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 93.3013%;
  top: 75%;
}

.time-5 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 75%;
  top: 93.3013%;
}

.time-6 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 100%;
}

.time-7 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 25%;
  top: 93.3013%;
}

.time-8 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 6.69873%;
  top: 75%;
}

.time-9 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 0%;
  top: 50%;
}

.time-10 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 6.69873%;
  top: 25%;
}

.time-11 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 25%;
  top: 6.69873%;
}

.clockContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  height: 310px;
  border-radius: 2px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
  position: absolute;
  z-index: 1;
  right: 0;
}

.clock {
  background-color: #3a4b5a;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  position: relative;
  border: 25px solid #3a4b5a;
}

.box {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  background-color: #151b23;
  margin-left: 2px;
}

.time {
  display: flex;
  justify-content: center;
  font-size: 46px;
  font-weight: 500;
  width: 100%;
  height: 60px;
  position: relative;
  top: 10px;
  padding-bottom: 10px;
}

input {
  height: 30px;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  width: 100%;
  min-width: 75px;
  box-sizing: border-box;
  cursor: pointer;
}

input:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

input:focus {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.95);
  outline: none;
}

.line {
  width: 0;
  height: 1px;
  background-color: #409875;
  transition: all ease 0.2s;
}

input {
  height: 30px;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  /* caret-color: transparent; */
  user-select: none;
}

input:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

input:focus {
  background-color: rgba(0, 0, 0, 0.45);
  color: rgba(255, 255, 255, 0.95);
  font-size: 14px;
  outline: none;
}

input::selection {
  background: none;
}

input::selection {
  background: none;
}

button:focus {
  font-weight: 600;
}

input:disabled {
  cursor: default;
}

@media only screen and (max-width: 576px) {
  .clockContainer {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>