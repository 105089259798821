import store from "../store/index";
import { createRouter, createWebHistory, } from 'vue-router'

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import(`../views/Welcome.vue`),
        meta: {
            alias: "Home",
            requiresAuth: true,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/Register.vue"),
        meta: {
            alias: "Registration",
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login.vue"),
        meta: {
            alias: "Log In",
        },
    },
    {
        path: "/forgotPassword",
        name: "LostPassword",
        component: () => import("../views/LostPassword.vue"),
        meta: {
            alias: "Forgot Password",
        },
    },
    {
        path: "/terms-of-service",
        name: "TOS",
        component: () => import("../views/TOS.vue"),
        meta: {
            alias: "Terms Of Service",
        },
    },
    {
        path: "/download",
        name: "Download",
        component: () => import("../views/Download.vue"),
        meta: {
            alias: "Downloads",
        },
    },
    {
        path: "/faq",
        name: "FAQ",
        component: () => import("../views/FAQ.vue"),
        meta: {
            alias: "FAQ",
        },
    },
    {
        path: "/resetpassword/:hash",
        name: "ResetPassword",
        component: () => import("../views/ResetPassword.vue"),
        meta: {
            alias: "Reset Password",
        },
    },
    {
        path: "/now/:id",
        name: "Now",
        component: () => import("../views/Meeting.vue"),
        meta: {
            alias: "Meeting",
        },
    },
    {
        path: "/calendar",
        name: "Calendar",
        component: () => import("../views/Calendar.vue"),
        meta: {
            alias: "Calendar",
            requiresAuth: true,
        },
    },
    {
        path: "/calendar/:view",
        name: "CalendarDetails",
        redirect: "/calendar/week/date",
        meta: {
            alias: "Calendar",
        },
    },
    {
        path: "/calendar/:view/:date",
        name: "CalendarDate",
        component: () => import("../views/Calendar.vue"),
        meta: {
            alias: "Calendar",
            requiresAuth: true,
        },
    },
    {
        path: "/schedule/:edit",
        name: "ScheduleEdit",
        component: () => import("../views/Schedule.vue"),
        meta: {
            alias: "Edit your Meeting",
            requiresAuth: true,
        },
    },
    {
        path: "/schedule",
        name: "Schedule",
        component: () => import("../views/Schedule.vue"),
        meta: {
            alias: "Schedule A Meeting",
            requiresAuth: true,
        },
    },
    {
        path: "/rooms",
        name: "Rooms",
        component: () => import("../views/Rooms.vue"),
        meta: {
            alias: "Rooms",
            requiresAuth: true,
            blockUserRoll: false
        },
    },
    {
        path: "/attendees",
        name: "Attendees",
        component: () => import("../views/Attendees.vue"),
        meta: {
            alias: "Attendees",
            requiresAuth: true,
        },
    },
    {
        path: "/rate",
        name: "Rate",
        component: () => import("../views/RateExperience.vue"),
        meta: {
            alias: "Rate Me",
        },
    },
    {
        path: "/:catchAll(.*)",
        component: () => import("../views/404.vue"),
        meta: {
            alias: "404 - Page Not Found",
        },
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach(async (to, from, next) => {

    let login = from.name == "Login";
    let register = from.name == "Register";
    let dashboard = to.name == "Home";
    let allowUser = to.meta.blockUserRoll;
    let authenticated = store.state.user.email;

    store.commit("user/routerHistory", to.name);

    if ((login || register) && dashboard) {
        if (authenticated) return next();
    }

    if (store.state.user.email && to.name == 'Login') return next({ name: "Home" });
    if (allowUser && store.state.user.role == 'user') return next({ name: "Home" });
    if (!to.meta.requiresAuth) return next();
    if (!authenticated) return next({ name: "Login" });
    if (authenticated) return next();
    return next();
});

router.afterEach((to, from) => {
    const pageTitle = to.meta.alias;
    const page = `| ${pageTitle}`
    document.title = `SecurMeet ${pageTitle ? ` ${page}` : ''}`;
});

export default router;
