<template>
  <transition name="dropdown-top">
    <div v-if="show" class="notification noSelect" @click.prevent="closeModal">
      <div class="d-flex w100">
        <div v-if="data.error" class="grow center">
          {{ data.error }}
        </div>
        <div v-if="data.success" class="grow center">
          {{ success(data.success) }}
        </div>
        <s-icon v-if="data.error" width="24" color="red" class="pl-20">
          close
        </s-icon>
        <s-icon v-if="data.success" width="24" color="green" class="pl-20">
          check
        </s-icon>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const store = useStore();
const notifications = computed(() => store.state.notifications.notifications);


const show = ref(false);
const data = ref({});
const buffer = ref([]);
const processing = ref(false);
const close = ref(false);
const interval = ref(null);
const count = ref(1);

const process = async () => {
  if (buffer.value.length == 0) return;
  if (processing.value) return;
  processing.value = true;
  show.value = true;
  for (let x of buffer.value) {
    await renderNotification(x);
    await timer();
    clearInterval(interval.value);
    close.value = false;
    if (!show.value) break;
  }
  store.commit("notifications/clearData");
  data.value = {};
  buffer.value = [];
  processing.value = false;
  show.value = false;
};

const renderNotification = async (x) => {
  data.value = {};
  setTimeout(() => {
    data.value = x;
  }, 0);

};

const timer = () => {
  return new Promise((resolve) => {
    interval.value = setInterval(() => {
      count.value++;
      if (count.value == 30) {
        if (buffer.value.length < 1) return;
        let first = JSON.stringify(buffer.value[0]);
        let second = JSON.stringify(buffer.value[1]);
        if (first !== second) return;
        closeModal(resolve);
      }
      if (count.value >= 60 || close.value) {
        closeModal(resolve);
      }
    }, 100);
    setTimeout(resolve, 2000);
  });
};

const closeModal = (resolve) => {
  count.value = 1;
  show.value = false;
  clearInterval(interval.value);
  close.value = true;
  store.commit("notifications/clearData");
  if (!resolve.type) setTimeout(resolve, 200);
};

const success = (val) => {
  if (val === "Success") return t("server.success");
  return val;
};

watch(() => notifications, (val, old) => {
  console.log("🚀 ~ notifications:", old)

  //if (buffer.value.length > 0) return;
  buffer.value = notifications.value;
  process();
});

watch(buffer, () => {
  console.log("🚀 ~ buffer:", notifications.value)
  buffer.value = notifications.value;
  process();
});

onMounted(() => {
// buffer.value = notifications.value;
  //store.commit("notifications/clearData");
});

</script>
<style lang="less" scoped>
.notification {
  background-color: #47657fcb;
  width: 300px;
  min-height: 72px;
  position: fixed;
  right: 15px;
  top: 80px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  font-size: 13px;
  cursor: pointer;
  z-index: 8;
}

.red_border {
  border: 1px solid rgba(223, 81, 81, 0.55);
}

.green_border {
  border: 1px solid hsla(156, 41%, 42%, 0.5);
}

@media only screen and (max-width: 576px) {
  .notification {
    width: 100%;
    right: 0px;
    top: 0px;
    background-color: #47657f;
  }
}
</style>