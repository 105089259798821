<template>
  <section class="relative" v-click-outside="close">
    <div class="d-flex w100">
      <div class="w100">
        <input  @click="toggle" ref="textRef" v-model="selected" data-lpignore="true" type="input" :style="inputStyling" autocomplete="do-not-autofill" class="w100" :class="{ noedit: noedit, hideCaret: !show }" @keydown.prevent.enter="toggle" @keydown.prevent.esc="close" @keydown.prevent.down="firstOnList()">
        <div ref="lineRef" class="line" />
      </div>
      <button  @click="toggle" v-if="!noedit" class="box center" type="button" tabindex="-1" :style="boxStyling">
        <s-icon width="20" height="20" color="grey">
          chevron-down
        </s-icon>
      </button>
    </div>
    <transition name="select">
      <section v-show="show" ref="dropdownRef" v-hotkey="{ esc: close }" class="dropdown" :style="dropdown">
        <div v-if="itemType.length === 0" class="grow center pa-20 col">
          <span>No results</span>
          <span v-if="selected" class="font-12 opacity-75">Invite "{{ selected }}" to the meeting</span>
        </div>
        <button v-for="(item, index) in itemType" :key="index" class="element" type="button" :style="element" :class="{ selected: item.selected, 'py-25': attendees == '' }" @keydown.prevent.shift.tab="up($event)" @keydown.prevent.tab.exact="down($event)" @keydown.prevent.down="down($event)" @keydown.prevent.up="up($event)" @click="clickHandle(item, true)">
          <div class="d-flex align-center gap-10">
            <div v-if="item.color" class="room_box mr-10" :style="{ backgroundColor: 'var(--sm-color-' + item.color + ')' }" />

            <div v-if="attendees !== ''" class="">
              {{ item.display }}
            </div>

            <div v-if="attendees == ''" class="font-14 blue--text align-center item1 gap-10 ml-10" :class="{ item8: !mq.smMinus }">
              <s-icon v-if="!item.group" color="blue">
                account
              </s-icon>
              <s-icon v-else :title="'Group'" color="blue">
                account-group
              </s-icon>
              <div v-if="!item.group" class="nowrap overflow-hidden col">
                <span class="overflow-hidden row justify-start">{{ item.display || item.value }}
                </span>
                <div v-if="item.display !== item.value" class="row">
                  <span class="font-12 opacity-75 grey--text nowrap overflow-hidden">
                    {{ item.value }}
                  </span>
                </div>
              </div>
              <div v-else class="col overflow-hidden nowrap align-start">
                {{ item.display }}
                <span class="font-12 opacity-75 grey--text nowrap overflow-hidden">
                  {{ item.attendees }}
                </span>
              </div>
            </div>
          </div>
          <s-icon v-show="item.selected" :key="key" class="noPointer mr-10" height="16" color="white">
            check
          </s-icon>
        </button>
      </section>
    </transition>
  </section>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useMq } from "vue3-mq";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const emit = defineEmits();

const props = defineProps([
  "height",
  "value",
  "noedit",
  "items",
  "multi",
  "searchable",
  "max",
  "attendees",
  "customPlaceholder",
  "invitees"
]);

const { t } = useI18n();
const mq = useMq();
const store = useStore();

const show = ref(false);
const selected = ref("");
const placeholder = ref("");
const key = ref(0);
const localItems = ref([]);
const multiSelect = ref([]);
const textRef = ref(null);
const lineRef = ref(null);
const dropdownRef = ref(null);

const toggle = () => {
  show.value ? close() : open();
}


const open = () => {
  textRef.value.placeholder = placeholder.value;
  emit("focused");
  if (props.noedit) return;
  show.value = true;
  textRef.value.focus();
  lineRef.value.style.width = "100%";
  if (props.searchable === "") selected.value = "";
}

const close = () => {
  if (!show.value) return;
  const exists = localItems.value.find(el => el.value == selected.value || el.display == selected.value);
  if (selected.value && exists && props.invitees) clickHandle(exists, false);
  textRef.value.placeholder = placeholder.value;
  emit("focused");
  if (props.noedit) return;
  show.value = false;
  lineRef.value.style.width = "0%";
  textRef.value.focus();
}

const clickHandle = (e, block) => {
  const modify = e.selected ? -1 : 1;
  if (multiSelect.value.length + modify > props.max) {
    return emit("add");
  }
  if (!multiple.value) localItems.value.forEach(unselect);
  if (!e.selected) {
    e.selected = true;
    multiSelect.value.push(e);
    key.value += 1;
  } else {
    key.value += 1;
    if (multiple.value) {
      e.selected = false;
      const index = multiSelect.value.findIndex(el => el.value == e.value);
      multiSelect.value.splice(index, 1);
    }
  }
  emit("select", {
    value: e.value,
    display: e.display,
    selected: e.selected,
  });
  textRef.value.placeholder = e.value;
  if (e.group) textRef.value.placeholder = e.display;
  if (props.searchable !== "") selected.value = e.display;
  if (multiple.value) return emit("multiSelect", multiSelect.value);;
  emit("update:modelValue", e);
  if (multiple.value) return;
  emit("blur");
  if (block) close();
}

const unselect = (val) => {
  val.selected = false;
}

const filter = (val) => {
  return val.selected == true;
}

const updateSelected = () => {
  if (multiple.value) return;
  selected.value = localItems.value.filter(filter)[0];
  if (!selected.value) return;
  selected.value = selected.value.display;
}

const down = (el) => {
  el = el.target;
  if (el.nextSibling) return el.nextSibling.focus();
}

const up = (el) => {
  el = el.target;
  if (el.previousSibling) return el.previousSibling.focus();
}

const focusSelectNode = () => {
  if (props.searchable === "") return;
  const selected = localItems.value.findIndex(filter);
  if (!selected) return;
  let buttons = dropdownRef.value.querySelectorAll('button');
  if (!buttons) return;
  buttons[selected]?.focus();
}

const firstOnList = () => {
  const universe = document.querySelectorAll(".element");
  universe[0].focus();
}


const inputStyling = computed(() => {
  let ob = {};
  if (props.noedit) ob.backgroundColor = "#00000000";
  if (props.height) ob.height = props.height + "px";
  return ob;
});

const element = computed(() => {
  let ob = {};
  if (props.height) ob.height = props.height + "px";
  return ob;
});

const boxStyling = computed(() => {
  let ob = {};
  if (props.height) ob.height = props.height + "px";
  if (props.height) ob.minWidth = props.height + "px";
  return ob;
});

const dropdown = computed(() => {
  let ob = {};
  if (props.height) ob.top = 1 + props.height + "px";
  return ob;
});

const multiple = computed(() => {
  let con1 = props.multi == true;
  let con2 = props.multi !== "";
  let con3 = typeof props.multi == "undefined";
  return con1 || con2 || con3 ? false : true;
});

const filtered = computed(() => {
  let con = selected.value.toLowerCase();
  return localItems.value.filter((val) => {
    if (!val?.display) val.display = "";
    if (!val?.value) val.value = "";
    return (
      val.display.toLowerCase().search(con) !== -1 ||
      String(val.value).toLowerCase().search(con) !== -1
    );
  });
});

const lang = computed(() => store.state.user.lang);

watch(show, async (newVal, oldVal) => {
  if (!show.value) return;
  if (!newVal) return;
  setTimeout(() => { focusSelectNode(); })

});

watch(lang, (newVal, oldVal) => {
  localItems.value = props.items;
  placeholder.value = props.customPlaceholder || t('atoms.choose');
  textRef.value.placeholder = placeholder.value;

  if (multiple.value) {
    for (let x of localItems.value) {
      if (x.selected) {
        multiSelect.value.push(x);
      }
    }
  }

  if (!localItems.value || multiple.value) return;

  setTimeout(() => {
    let index = localItems.value.findIndex(el => el?.selected == true);
    if (index == -1) index = 0;
    if (localItems.value.length == 0) return;
    localItems.value[index].selected = true;
    updateSelected();
    emit("update:modelValue", localItems.value[index]);
    emit("focused");
  }, 0);
});

watch(() => props.items, (val, oldVal) => {
  localItems.value = props.items;
  if (!localItems.value) return;
  updateSelected();
  for (let x of localItems.value) {
    if (x.selected) {
      multiSelect.value.push(x);
    }
  }
});

watch(selected, (newVal, oldVal) => {
  if (props.searchable === "") show.value = true;
  emit("typed", newVal);
});

const itemType = computed(() => {
  return props.searchable === "" ? filtered.value : localItems.value;
});

onMounted(() => {
  localItems.value = props.items;
  placeholder.value = props.customPlaceholder || t('atoms.choose');
  textRef.value.placeholder = placeholder.value;

  if (multiple.value) {
    for (let x of localItems.value) {
      if (x.selected) {
        multiSelect.value.push(x);
      }
    }
  }

  if (!localItems.value || multiple.value) return;

  setTimeout(() => {
    let index = localItems.value.findIndex(el => el?.selected == true);
    if (index == -1) index = 0;
    if (localItems.value.length == 0) return;
    localItems.value[index].selected = true;
    updateSelected();
    emit("update:modelValue", localItems.value[index]);
    emit("focused");
  }, 0);
});
</script>

<style scoped lang='less'>
.dropdown {
  position: absolute;
  background-color: #151b23;
  z-index: 1;
  font-size: 14px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  min-width: 100%;
  max-height: 250px;
  overflow: overlay;
  top: 31px;
}

input {
  height: 30px;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
  text-align: start;
}

input:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.element {
  height: 30px;
  width: 133px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  color: rgb(160, 160, 160);
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
}

.element:hover {
  background-color: #00000054;
  color: rgb(255, 255, 255);
}

.item:focus {
  background-color: #000000a3;
  color: rgb(255, 255, 255);
}

.box {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  background-color: #151b23;
  margin-left: 2px;
}

.line {
  width: 0;
  height: 1px;
  background-color: #409875;
  transition: all ease 0.2s;
}

input:focus {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.95);
  outline: none;
}

input:focus-visible {
  background-color: rgba(0, 0, 0, 0.45);
}

button:focus-visible {
  background-color: rgba(0, 0, 0, 0.45);
}

input::selection {
  background: none;
}

.noedit {
  cursor: default;
}

.hideCaret {
  caret-color: transparent;
}

.noedit:focus {
  color: rgba(255, 255, 255, 0.75);
}

.room_box {
  height: 10px;
  width: 10px;
}

::placeholder {
  color: rgba(255, 255, 255, 0.75);
}



@media only screen and (max-width: 576px) {
  .dropdown {
    left: -10px;
    top: 41px;
    width: 99vw;
  }
}
</style>